import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import VueEasyLightbox from 'vue-easy-lightbox'
import "@/assets/css/font.scss";
import 'amfe-flexible'

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

createApp(App)
    .use(router)
    .use(VueEasyLightbox)
    .mount('#app')
