import { createRouter, createWebHashHistory } from 'vue-router';

export const routes = [
    {
        path: "/",
        component: () => import("@/layouts/index.vue"),
        meta: { title: "信广龙" },
        children: [
            {
                path: "/",
                component: () => import("@/views/Home.vue"),
                meta: { title: "信广龙" },
            },
            {
                path: "/news",
                component: () => import("@/views/News.vue"),
                meta: { title: "招聘信息" },
            }
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router